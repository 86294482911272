import { createApp } from 'vue'
import App from './App.vue'
import {apiAuthorizeUrl, authEnabled} from "@/config/api";
import Keycloak from 'keycloak-js';


if (authEnabled) {
    const initOptions = {
        url: apiAuthorizeUrl, realm: 'axesor', clientId: 'persistor-front', onLoad: 'login-required'
    }
    const keycloak = new Keycloak(initOptions)
    keycloak
        .init({onLoad: initOptions.onLoad})
        .then((auth) => {
            if (!auth) {
                window.location.reload()
            } else
                createApp(App, {keycloak: keycloak})
                    .mount('#app')

            setInterval(() => {
                keycloak
                    .updateToken(70)
                    .catch(() => {
                        console.log('Failed to refresh token')
                    })
            }, 6000)

        }).catch((error) => {
        console.log(error)
        console.log("Authenticated Failed")
    })
}
else {
    const keycloak = {
        token: "fake",
        resourceAccess: {persistor: {roles: ""}}
    }
    createApp(App,{keycloak: keycloak})
        .mount('#app')
}


