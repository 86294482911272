<template>
  <div id="app">
    <div id="swagger-ui"></div>
  </div>

</template>

<script>

import SwaggerUIBundle from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css';
import {apiUrl} from "@/config/api";

export default {
  name: 'swagger_ui',
  props: ['keycloak'],
  mounted () {
    const keycloak= this.keycloak
    setInterval(() => {
      ui.preauthorizeApiKey("bearerAuth", keycloak.token)
    }, 6000)
    const ui = SwaggerUIBundle({
      url: 'openapi.yaml',
      dom_id: '#swagger-ui',
      deepLinking: false,
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset
      ],
      onComplete: function() {
        ui.preauthorizeApiKey("bearerAuth", keycloak.token)
        let spec = ui.specSelectors.specJson().toJS()
        spec.servers = [{url: apiUrl}]
        ui.specActions.updateJsonSpec(spec)
      },
    })
  }
}
</script>

<style>

#app {
  margin: 60px 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>